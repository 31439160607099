h1, h2, h3, h4, h6 {
    font-weight: 700;
    line-height: 1.4;
}

h1 {
    font-size: 2.625rem;
    &.yesteryear {
        font-weight: 400;
    }
    @media only screen and (max-width: 639px) {
        font-size: 32px;
        font-weight: 800;
        margin-top: 40px;
    }
}

h2 {
    font-size: 2.125rem;
    &.yesteryear {
        font-weight: 400;
    }
    @media only screen and (max-width: 639px) {
        font-size: 22px;
    }
}

h3 {
    font-size: 1.5rem;
    &.yesteryear {
        font-weight: 400;
    }
    @media only screen and (max-width: 639px) {
        font-size: 20px;
    }
}

h4 {
    font-size: 18px;
    &.yesteryear {
        font-weight: 400;
    }
    @media only screen and (max-width: 639px) {
        font-size: 18px;
    }
}

h6 {
    font-size: 16px;
    @media only screen and (max-width: 639px) {
        font-size: 14px;
    }
}

.lead {
    font-size: 18px;
    font-weight: 300;
    @media only screen and (max-width: 639px) {
        font-size: 14px;
    }


        
       
}